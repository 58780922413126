import Client from './Client';

export default class CommonClient {
  constructor() {
    this.client = new Client();
  }

  /**
   * 获取账号列表
   */
  async searchH5(data) {
    const res = await this.client.post('/api/user/course/cert/searchH5', data);
    return res;
  }
}
