<template>
  <div class="video-list">
    <div class="default-tips" v-if="videoExamList.length === 0 && offlineVideoList.length === 0">
      请联系机构/平台上传视频
    </div>
    <div v-else>
      <div class="info-panel">
        <div class="panel-title">
            线上视频
        </div>
        <div
          class="item"
          v-for="(item,index) in videoExamList"
          :key="item.id">
            <div
              v-if="item.isOnCert === 1"
            
            >
              <videoPlayer
              :ref="`videoPlayer${index}`"
              :options="playerOptions[index]"
              @play="onPlayerPlay($event)"
              @pause="onPlayerPause($event,index)"
              :playsinline="playsinline"
              />
              <div class="mask" v-if="!activePlayIndex.includes(index)" @click="play(index)">
                <div class="title">{{item.chapterLv1Name}}</div>
                <div class="play">
                  <img src="../assets/play.png" alt="">
                </div>
                <div class="time">
                  {{dayjs(Number(item.lastRecordCreateTime)).format('YYYY-MM-DD hh:mm:ss')}}
                </div>
              </div>
          </div>
        </div>
    </div>
    <div class="info-panel">
      <div class="panel-title">
          线下视频
      </div>
      <div
        class="item"
        v-for="(item,index) in offlineVideoList"
        :key="item.id">
        <div
         v-if="item.isShow === 1"
        >
          <videoPlayer
          :ref="`videoPlayer${index + videoExamList.length}`"
          :options="playerOptions[index + videoExamList.length]"
          @play="onPlayerPlay($event)"
          @pause="onPlayerPause($event,index + videoExamList.length)"
          :playsinline="playsinline"
          />
          <div class="mask"
            v-if="!activePlayIndex.includes(index + videoExamList.length)"
            @click="play(index + videoExamList.length)">
            <div class="title">{{item.showChapterName}}</div>
            <div class="play">
            <img src="../assets/play.png">
          </div>
          <div class="time">
          {{dayjs(Number(item.showCreateTime)).format('YYYY-MM-DD hh:mm:ss')}}
          </div>
        </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';

export default {
  name: 'VideoList',
  props: {
    msg: String,
  },
  components: { videoPlayer },
  data() {
    return {
      dayjs,
      videoExamList: [],
      offlineVideoList: [],
      playerOptions: [],
      activePlayIndex: [],
    };
  },
  methods: {
    play(index) {
      this.$refs[`videoPlayer${index}`][0].player.play();
      this.activePlayIndex.push(index);
    },
    onPlayerPlay() {
    },
    onPlayerPause(event, index1) {
      const index = this.activePlayIndex.indexOf(index1);
      this.activePlayIndex.splice(index, 1);
    },
  },
  computed: {
    playsinline() {
      const ua = navigator.userAgent.toLocaleLowerCase();
      // x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    const videoExamList = localStorage.getItem('videoExamList');
    const offlineVideoList = localStorage.getItem('offlineVideoList');
    if (videoExamList) {
      this.videoExamList = JSON.parse(videoExamList);
      this.videoExamList.forEach((item, index) => {
        const playerOption = {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选择的播放速度
          autoplay: false, // 如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: {
            type: '',
            src: '', // url地址
          },
          poster: '', // 你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true, // 当前时间和持续时间的分隔符
            durationDisplay: true, // 显示持续时间
            remainingTimeDisplay: false, // 是否显示剩余时间功能
            fullscreenToggle: true, // 全屏按钮
          },
        };
        playerOption.sources.src = item.lastRecordVideoUrl;
        this.playerOptions[index] = playerOption;
      });
    }
    if (offlineVideoList) {
      this.offlineVideoList = JSON.parse(offlineVideoList);
      this.offlineVideoList.forEach((item, index) => {
        const playerOption = {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选择的播放速度
          autoplay: false, // 如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 视频一结束就重新开始。
          preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: 'zh-CN',
          aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: {
            type: '',
            src: '', // url地址
          },
          poster: '', // 你的封面地址
          // width: document.documentElement.clientWidth,
          notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true, // 当前时间和持续时间的分隔符
            durationDisplay: true, // 显示持续时间
            remainingTimeDisplay: false, // 是否显示剩余时间功能
            fullscreenToggle: true, // 全屏按钮
          },
        };
        playerOption.sources.src = item.videoUrl;
        this.playerOptions[index + this.videoExamList.length] = playerOption;
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.video-list{
  padding: 15px;
  .default-tips{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 400px;
    font-size: 30px;
  }
   @media (min-width: 650px) {
   .video-player{
      height: 600px !important;
    }
  }
  .item{
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    .video-player{
      width: 100%;
      height: 200px;
      ::v-deep .vjs-big-play-button{
        display: none;
      }
      ::v-deep .video-js{
        padding-top: 0px;
        height: 100%;
      }
    }
    .mask{
      color: #ffffff;
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 5px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      z-index: 1;
      justify-content: space-between;
      .title{
          font-size: 20px;
        }
      .time{
        font-size: 14px;
        text-align: right;
      }
      .play{
        text-align: center;
        img{
          width: 42px;
        }
      }
    }
  }
  .info-panel{
    padding: 20px 15px;
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 20px;
    .panel-title{
      color: #333333;
      font-size: 20px;
      font-weight: 500;
      position: relative;
      padding-left: 15px;
      margin-bottom: 10px;
      &::after {
        content: '';
        position: absolute;
        width: 5px;
        top: 0px;
        bottom: 0px;
        left: 0;
        background: linear-gradient(180deg, #112BE9 0%, #FFFFFF 100%);;
      }
    }
  }
}
</style>
