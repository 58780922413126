<template>
  <div class="index">
    <div class="top">
      <div class="title">
        金牌育儿专家 <br>
        专注更专业
      </div>
      <div class="tips">
        一站式育婴服务，专注宝宝成长教 <br> 育，贴心更放心
      </div>
    </div>
    <form action="javascript:return true" @submit.prevent="formSubmit" class="input-item ">
      <input type="search" @keyup.enter="searchH5"  v-model.trim="value" placeholder="请输入身份证号码">
      <div class="btn"  ref="submit" @click="searchH5">
        <img src="../assets/serach.png" alt="">
      </div>
    </form>
    <div v-if="result">
      <div class="info-panel user-info">
        <div class="panel-title">
            个人信息
          </div>
        <div class="info-item">
          <div class="label">姓名</div>
          <div class="value">{{result.userName}}</div>
        </div>
        <div class="info-item">
          <div class="label">性别</div>
          <div class="value">{{result.userSex}}</div>
        </div>
        <div class="info-item">
          <div class="label">出生年月</div>
          <div class="value">{{result.birthday}}</div>
        </div>
        <div class="info-item">
          <div class="label">身份证号</div>
          <div class="value">{{result.userIdCard}}</div>
        </div>
      </div>
      <div class="info-panel cert-info"
        :key="cert.id"
        :class="{'active': activeCertIndex.includes(index)}"
        v-for="(cert,index) in result.certList">
        <div class="panel-title">
            证书详情({{index+1}})
        </div>
        <div class="info-item cert-name"
          @click="clickIcon(index)">
          <div class="label">证书名称</div>
          <div class="value">{{cert.courseName}}</div>
          <div class="icon" >
            <img src="../assets/up.png">
          </div>
        </div>
        <div v-if="activeCertIndex.includes(index)"
        >
        <div class="info-item">
          <div class="label">姓名</div>
          <div class="value">{{cert.userName}}</div>
        </div>
        <div class="info-item">
          <div class="label">证书编号</div>
          <div class="value">{{cert.certNo}}</div>
        </div>
        <div class="info-item">
          <div class="label">成绩</div>
          <div class="value">{{cert.scoreStr}}</div>
        </div>
        <div class="info-item">
          <div class="label">发证日期</div>
          <div class="value">
            {{cert.certDate}}
            </div>
        </div>
        <div class="bottom">
          <a class="btn" :href="cert.certUrl" >查看电子证书</a>
          <div class="btn" @click="goVideo(cert)">考试视频</div>
        </div>
        </div>
      </div>
    </div>
    <!-- <div class="about-us info-panel" >
      <div class="panel-title">
          关于口袋阿姨
      </div>
      <img src="../assets/logo.png" class="logo" alt="">
      <div class="info"></div>
    </div> -->
    <div class="contact info-panel">
      <div class="left">
        <div class="panel-title">
          联系我们
        </div>
      <div class="info-item">
        <div class="label">电话:</div>
        <div class="value">021-26015579</div>
      </div>
      <div class="info-item">
        <div class="label">地址:</div>
        <div class="value">上海市闵行区虹井路280弄1号307A室</div>
      </div>
      </div>
      <div class="right">
        <img src="../assets/qr.jpg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import axClient from '@/services';
import dayjs from 'dayjs';

export default {
  name: 'PageIndex',
  props: {
    msg: String,
  },
  data() {
    return {
      dayjs,
      result: null,
      value: '',
      activeCertIndex: [],
      diabledBtn: false,
    };
  },
  methods: {
    async searchH5() {
      if (this.diabledBtn) return;
      this.diabledBtn = true;
      const data = await axClient.common.searchH5({ value: this.value });
      if (data) {
        this.result = data;
      } else {
        // eslint-disable-next-line no-alert
        window.alert('查询结果为空');
        this.result = null;
      }
      setTimeout(() => {
        this.diabledBtn = false;
      }, 1500);
    },
    formSubmit() {
      return false;
    },
    goVideo(item) {
      if (item.videoExamList) {
        localStorage.setItem('videoExamList', JSON.stringify(item.videoExamList));
      } else {
        localStorage.removeItem('videoExamList');
      }
      if (item.offlineVideoList) {
        localStorage.setItem('offlineVideoList', JSON.stringify(item.offlineVideoList));
      } else {
        localStorage.removeItem('offlineVideoList');
      }
      this.$router.push({ name: 'videoList' });
    },
    clickIcon(index) {
      const index1 = this.activeCertIndex.indexOf(index);
      if (index1 === -1) {
        this.activeCertIndex.push(index);
      } else {
        this.activeCertIndex.splice(index1, 1);
      }
    },
  },
  created() {
    const { idCard } = this.$route.query;
    this.value = idCard;
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.$refs.submit.click();
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.index{
  width: 100%;
  .top{
    background: url('../assets/top.png') no-repeat center center;
    background-size: cover;
    width: 414px;
    height: 308px;
    padding-top: 80px;
    padding-left: 30px;
    color: #ffffff;
    width: 100%;
    @media (min-width: 650px) {
   .title{
      font-size: 40px !important;
    }
    .tips{
      font-size: 16px !important;
      margin-top: 30px !important;
    }
  }
    .title{
      font-size: 27px;
      font-weight: 500;
    }
    .tips{
      font-size: 12px;
      line-height: 17px;
      margin-top: 10px;
    }
  }
  
  .input-item{
    background: #FFFFFF;
    padding: 27px 23px;
    margin-left:20px;
    margin-right: 20px;
    margin-top: -60px;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 12px;
    input{
      background: #EFEFEF;
      border-radius: 12px;
      border: none;
      height: 44px;
      padding-left: 20px;;
      flex: 1;
      -webkit-appearance: none;
    }
    .btn{
      position: absolute;
      right: 28px;
      width: 40px;
      height: 40px;
      background: #0259FE;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 24px;
      }
    }
  }
  .info-panel{
    padding: 20px 15px;
    margin: 20px 20px;
    background: #FFFFFF;
    border-radius: 12px;
    .cert-name{
      background: rgba(2, 89, 254, .04);
      border-radius: 10px;
      .icon{
        width: 12px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        transform: rotate(-180deg);
        img{
          width: 100%;
        }
      }
    }
    &.active{
      padding-bottom: 0px;
       .icon{
          transform: rotate(0deg);
      }
    }
    .panel-title{
      color: #333333;
      font-size: 20px;
      font-weight: 500;
      position: relative;
      padding-left: 15px;
      margin-bottom: 10px;
      &::after {
        content: '';
        position: absolute;
        width: 5px;
        top: 0px;
        bottom: 0px;
        left: 0;
        background: linear-gradient(180deg, #112BE9 0%, #FFFFFF 100%);;
      }
    }
    .info-item{
      font-size: 16px;
      display:flex;
      align-self: center;
      padding: 10px 0;
      .label{
        color: #666666;
        min-width: 74px;
        display: inline-flex;
        align-items: center;
        margin-right: 20px;
        padding-left: 10px;
      }
      .value{
       color: #333333;
       line-height: 22px;
      }
    }
    .bottom{
      padding: 16px;
      display: flex;
      align-items: center;
      font-size: 16px;
      border-top: 1px solid #EFEFEF;
      .btn{
        flex: 1;
        position: relative;
        text-align: center;
        color: #0259FE;
        text-decoration: none;
        &:first-child{
          &::after{
            content: '';
            position: absolute;
            width: 1px;
            top:0;
            right: 0;
            bottom: 0;
            background: #EFEFEF;
          }
        }
      }
    }
  }
  .about-us{
    .logo{
      width: 100%;
    }
    .info{
      margin-top: 20px;
      color: #999999;
      font-size: 18px;
      line-height: 24px;
      text-indent:36px;
    }
  }
  .contact{
    display: flex;
    .info-item{
      .label{
        min-width: 40px;
        padding-left: 0px;
      }
      .value{
        color: #666666;
      }
    }
    .left,.right{
      img{
        width: 100%;
      }
    }
    .left{
      flex: 4,
    }
    .right{
      flex: 2;
      display: flex;
      align-items: center;
    }
  }
}
</style>
