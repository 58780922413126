<template>
  <div id="app">
    <router-view />
    <div class="footer">
      copyright@rjc.org版权所有2021上海口袋阿姨职业技能培训有限公司
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
};
</script>

<style lang="scss" scoped>
#app {
  font-family: 'PingFang-SC-Medium', 'PingFang-SC';
  min-height: 100vh;
  padding-bottom: 60px;
  position: relative;
  width: 100vw;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ffffff;
    font-size: 12px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0259FE;
  }
}
</style>
