import axios from 'axios';

const config = require('../../../httpConfig');

/**
 * A Client to Cybertron nodejs using Ajax.
 */
export default class Client {
  constructor() {
    // It's possible to override the address by a querystring.
    const hostDev = config.dev;
    const hostProd = config.prod;
    let addressQ = '';
    // The nodejs is serving the page so using the same address.
    // console.log(process.env.NODE_ENV, process.env.VUE_APP_HOST_ENV);
    if (process.env.NODE_ENV === 'development') {
      addressQ = '';
    } else if (process.env.NODE_ENV === 'production') {
      if (process.env.VUE_APP_HOST_ENV === 'dev') {
        addressQ = hostDev ? `${hostDev}` : null;
      } else {
        addressQ = hostProd ? `${hostProd}` : null;
      }
    }
    const axiosInstance = axios.create({
      timeout: 10000,
      headers: {
        token: localStorage.getItem('token'),
      },
    });
    axiosInstance.interceptors.request.use((requestConfig) => requestConfig);
    // axios response intercept
    axiosInstance.interceptors.response.use((res) => {
      const { data } = res;
      if (data.code === '10000') {
        return data.data ? data.data : null;
      }
      return null;
    }, (err) => Promise.reject(err));
    this.address = addressQ;
    this.url = `${addressQ}`;
    this.axiosInstance = axiosInstance;
  }

  async get(path) {
    const res = await this.axiosInstance.get(this.url + path, {
      responseType: 'json',
      withCredentials: true,
      validateStatus: () => true,
    });
    return res;
  }

  async post(path, json) {
    const res = await this.axiosInstance.post(this.url + path, json, {});
    return res;
  }
}
